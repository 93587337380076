import React from "react"
import { Link } from "gatsby"
import { Navbar, Container, Nav } from "react-bootstrap"
import "./index.scss"
import Logo from "../../../assets/images/menu/logo.svg"

const TopNav = () => {
  const openNav = () => {
    document.getElementById("myNav").style.height = "100%"
  }

  const closeNav = () => {
    document.getElementById("myNav").style.height = "0%"
  }

  const fullScreenNavLinks = () => (
    <Nav>
      <Nav.Link href="/about-us">About us</Nav.Link>
      <Nav.Link href="/products">Products</Nav.Link>
      <Nav.Link href="/ventures">Ventures</Nav.Link>
      {/* <Nav.Link eventKey={2} href="/">Countries</Nav.Link> */}
      <Nav.Link eventKey={3} href="/news">News</Nav.Link>
      {/* <Nav.Link eventKey={4} href="/contact">Contact</Nav.Link> */}
    </Nav>
  )

  const smallScreenNavLinks = () => (
    <div className="overlay-content">
      <Link to="/about-us">About us</Link>
      <Link to="/products">Products</Link>
      <Link to="/ventures">Ventures</Link>
      {/* <Link to="/countries">Countries</Link> */}
      <Link to="/news">News</Link>
      {/* <Link to="/contact">Contact</Link> */}
    </div>
  )

  return (
    <div>
      {/* <div className="green__header">
        <p>Change is here! We are now Fin (formerly Finclusion Group).</p>
      </div> */}
      <Navbar collapseOnSelect expand="lg" className="topNav__container">
        <Container fluid>
          <Link to="/">
            <img
              src={require("../../../assets/images/logo.png").default}
              alt="logo"
              style={{ maxHeight: "107px" }}
            />
          </Link>
          {fullScreenNavLinks()}
          <div id="myNav" className="overlay">
            <a href={void 0} className="closebtn" onClick={closeNav}>
              &times;
            </a>
            {smallScreenNavLinks()}
          </div>
          <span className="topNav__burger" onClick={openNav}>
            &#9776;
          </span>
        </Container>
      </Navbar>
    </div>
  )
}

export default TopNav
